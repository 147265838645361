import { get } from 'lodash';
import { createAction } from '@wix/communities-blog-client-common';
import { handleAggregatorResponse } from '../../services/aggregator';

export const FETCH_EXPERIMENTS_REQUEST = 'experiments/FETCH_EXPERIMENTS_REQUEST';
export const FETCH_EXPERIMENTS_SUCCESS = 'experiments/FETCH_EXPERIMENTS_SUCCESS';

export const fetchExperimentsRequest = createAction(FETCH_EXPERIMENTS_REQUEST);
export const fetchExperimentsSuccess = createAction(FETCH_EXPERIMENTS_SUCCESS);

export function fetchExperiments(experimentsPromise) {
  return async (dispatch) => {
    dispatch(fetchExperimentsRequest());

    try {
      const body = await experimentsPromise;
      return dispatch(fetchExperimentsSuccess(get(body, 'values', {})));
    } catch {
      return dispatch(fetchExperimentsSuccess({}));
    }
  };
}

export const handleExperimentsResponse = (response) =>
  fetchExperiments(handleAggregatorResponse({ ...response, body: { values: response.body } })());
