import { encodeURISlug } from '../../common/services/slug';

export const FETCH_CATEGORY_REQUEST = 'category/FETCH_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'category/FETCH_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'category/FETCH_FAILURE';

export const fetchCategoryRequest = () => ({ type: FETCH_CATEGORY_REQUEST });
export const fetchCategorySuccess = (payload) => ({ type: FETCH_CATEGORY_SUCCESS, payload });
export const fetchCategoryFailure = () => ({ type: FETCH_CATEGORY_FAILURE });

export function fetchCategory(categorySlug) {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCategoryRequest());

    const promise = request(`/_api/categories/${encodeURISlug(categorySlug)}`);

    return promise
      .then((category) => dispatch(fetchCategorySuccess(category)))
      .catch(() => dispatch(fetchCategoryFailure()))
      .then(() => promise);
  };
}
