import { createAction, urijs } from '@wix/communities-blog-client-common';
import { normalizePost } from '../services/post-utils';
import { getCategoryIds } from '../selectors/categories-selectors';
import { encodeURISlug } from '../services/slug';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

const makeRequest = (postSlug, { includeDraft, request, instance }) => {
  const fieldsets = `categories,owner,likes,content,subscriptions,tags,seo,translations`;
  const requestUrl = urijs(`/_api/posts/${!includeDraft ? 'content/' : ''}${postSlug}`).query({
    fieldsets,
  });
  return request(requestUrl, { instance });
};

export function fetchPost(postSlug, { includeDraft, instance } = {}) {
  return (dispatch, getState, { request }) => {
    const promise = preFetchPost(postSlug, { includeDraft, instance })(dispatch, getState, { request });
    return completeFetchPost(postSlug, promise)(dispatch, getState);
  };
}

export const preFetchPost = (postSlug, { includeDraft, instance } = {}) => (dispatch, getState, { request }) => {
  postSlug = encodeURISlug(postSlug);
  dispatch(fetchPostRequest({ postSlug }));
  return makeRequest(postSlug, { includeDraft, request, instance });
};

export const completeFetchPost = (postSlug, preFetchResult) => (dispatch, getState) => {
  postSlug = encodeURISlug(postSlug);
  let normalizedPost;
  return preFetchResult
    .then((post) => {
      normalizedPost = normalizePost({ post, blogCategoryIds: getCategoryIds(getState()) });
      return dispatch(
        fetchPostSuccess({
          post: normalizedPost,
          postSlug,
        }),
      );
    })
    .catch(({ status }) => dispatch(fetchPostFailure({ postSlug, error: { status } })))
    .then(() => preFetchResult.then(() => normalizedPost));
};
