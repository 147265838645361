import { get } from 'lodash';
import { ROUTE_404 } from '../../constants/routes';
import { SECTION_CATEGORY, getAppConfig } from '@wix/communities-blog-client-common';
import {
  getIsValidPage,
  getShowPagination,
  getPageSize as getPageSizeFromSettings,
} from '../../../common/selectors/pagination-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { resolveCategorySlug } from '../../../common/services/slug';
import { fetchCategoryPostsBySlug, completeFetchCategoryPosts } from '../../../common/actions/fetch-category-posts';
import { isSSR, isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../../common/actions/page-opened';
import { CATEGORY_PAGE } from '../../../common/services/detect-route';
import { generateCategorySEOTags } from '../../../common/services/generate-seo-tags/generate-category-seo-tags';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { translate } from '../../../common/services/controller-translate';
import { waitForAction } from '../../../common/store/wait-for-action';
import { SET_APP_SETTINGS } from '../../../common/store/app-settings/app-settings-actions';
import { SET_BASIC_PARAMS } from '../../../common/store/basic-params/basic-params-actions';
import { FETCH_CATEGORIES_SUCCESS } from '../../../common/store/categories/fetch-categories';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';

export const createCategoryPageRouter = (store, wixCodeApi) => async (
  { params },
  redirect,
  { preFetch, preFetchResult },
) => {
  const page = parseInt(get(params, 'page', '1'), 10);
  const slug = resolveCategorySlug(params);
  const shouldExcludeContent = () => isExcludePostContentSupported(store.getState(), SECTION_CATEGORY);

  if (preFetch) {
    return store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS, SET_BASIC_PARAMS, FETCH_CATEGORIES_SUCCESS],
        () =>
          fetchCategoryPostsBySlug({
            slug,
            page,
            excludeContent: shouldExcludeContent(),
            preFetch,
          }),
        true,
      ),
    );
  }

  try {
    await store.dispatch(
      preFetchResult
        ? completeFetchCategoryPosts(
            {
              slug,
              page,
              pageSize: getPageSizeFromSettings(store.getState(), { section: SECTION_CATEGORY }),
              excludeContent: shouldExcludeContent(),
            },
            preFetchResult,
          )
        : fetchCategoryPostsBySlug({
            slug,
            page,
            excludeContent: shouldExcludeContent(),
            preFetch,
          }),
    );

    const state = store.getState();
    const category = getCategoryBySlug(state, slug);
    const showPagination = getShowPagination(state, SECTION_CATEGORY);
    const isValidPage = getIsValidPage(state, SECTION_CATEGORY);
    if (showPagination && !isValidPage) {
      redirect(ROUTE_404);
    }

    if (isSite(state)) {
      const appConfig = getAppConfig(state);
      const sectionUrl = getSectionUrl(state);
      const categorySEOTags = await generateCategorySEOTags({
        appConfig,
        sectionUrl,
        showPagination,
        category,
        store,
        page,
        t: translate,
      });
      wixCodeApi.seo.renderSEOTags(categorySEOTags);
    }

    !isSSR(store.getState()) && store.dispatch(pageOpened({ page: CATEGORY_PAGE, category }));
  } catch (error) {
    if (error.status === 404) {
      return redirect(ROUTE_404);
    }
    throw error;
  }
};
