import { get } from 'lodash';
import { isExperimentEnabled, urijs } from '@wix/communities-blog-client-common';
import { EXPERIMENT_READABLE_SEO_URL } from '@wix/communities-blog-experiments';

export const getFeedSEOTags = (state) => state.feedSEOTags;

export const getFeedSEOTitle = (state) => get(state, 'feedSEOTags.title', '');
export const getFeedSEOLinks = (state) => get(state, 'feedSEOTags.links', []);
export const getFeedSEOMetaTags = (state) => {
  const isReadableSEOURLEnabled = isExperimentEnabled(state, EXPERIMENT_READABLE_SEO_URL);
  if (!isReadableSEOURLEnabled) {
    return get(state, 'feedSEOTags.metaTags', []);
  }
  const metaTags = get(state, 'feedSEOTags.metaTags', []);
  const ogUrlMetaTagIndex = metaTags.findIndex((item) => item.property === 'og:url');
  if (ogUrlMetaTagIndex > -1) {
    metaTags[ogUrlMetaTagIndex].content = urijs(metaTags[ogUrlMetaTagIndex].content).readable();
  }
  return metaTags;
};
