import { encodeURIComponentIfNeeded } from '../../services/uri';

export const getPathname = (location, sectionUrl = '') => {
  const lastPathPart = sectionUrl
    .split('/')
    .filter((part) => part)
    .pop();
  const indexOfLastPart = location.path.indexOf(lastPathPart);
  let path = [];
  if (indexOfLastPart !== -1) {
    path = location.path.slice(indexOfLastPart + 1);
  }

  return '/' + path.map(encodeURIComponentIfNeeded).join('/');
};
