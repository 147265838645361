import { createPageUrl, isExperimentEnabled, SECTION_HOMEPAGE } from '@wix/communities-blog-client-common';
import { EXPERIMENT_READABLE_SEO_URL } from '@wix/communities-blog-experiments';
import {
  getFeedSEOLinks,
  getFeedSEOMetaTags,
  getFeedSEOTitle,
} from '../../../feed-page/selectors/feed-seo-tags-selectors';
import { getEntityCount, getPaginationPageSize } from '../../selectors/pagination-selectors';
import { getTopology } from '../../store/topology/topology-selectors';
import { formatTitle, getLastPage } from '../pagination';
import { getPaginationUrls } from './get-pagination-item-data';

export const generateFeedSEOTags = ({ state, page = 1, t, showPagination }) => {
  const { baseUrl, sectionUrl, isHomePage } = getTopology(state);

  const isReadableSEOURLEnabled = isExperimentEnabled(state, EXPERIMENT_READABLE_SEO_URL);
  const canonicalUrl =
    page === 1
      ? isHomePage
        ? baseUrl
        : sectionUrl
      : createPageUrl(page, sectionUrl, { readable: isReadableSEOURLEnabled });

  let title = getFeedSEOTitle(state);
  const links = getFeedSEOLinks(state);
  const metaTags = getFeedSEOMetaTags(state);

  !links.find((item) => item.rel === 'canonical') && links.push({ rel: 'canonical', href: canonicalUrl });
  !metaTags.find((item) => item.property === 'og:url') && metaTags.push({ property: 'og:url', content: canonicalUrl });

  const pageSize = getPaginationPageSize(state, SECTION_HOMEPAGE);
  const entityCount = getEntityCount(state, 'posts');
  const lastPage = getLastPage(entityCount, pageSize);
  if (showPagination) {
    title = formatTitle({ title, page, lastPage, t });
  }

  const paginationUrls = getPaginationUrls(sectionUrl, page, lastPage, state);
  if (paginationUrls.prevUrl) {
    links.push({ rel: 'prev', href: paginationUrls.prevUrl });
  }
  if (paginationUrls.nextUrl) {
    links.push({ rel: 'next', href: paginationUrls.nextUrl });
  }

  return {
    title,
    links,
    metaTags,
  };
};
