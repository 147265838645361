import { get } from 'lodash';
import { ROUTE_404 } from '../../constants/routes';
import { SECTION_HOMEPAGE, IS_BANNER_ENABLED_PATH } from '@wix/communities-blog-client-common';
import fetchFeedPosts, { completeFetchFeedPosts } from '../../../common/actions/fetch-feed-posts';
import {
  getIsValidPage,
  getShowPagination,
  getPageSize as getPageSizeFromSettings,
} from '../../../common/selectors/pagination-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { fetchLastFeaturedPost, completeFetchLastFeaturedPost } from '../../../common/actions/fetch-last-featured-post';
import { isSSR, getIsMobile, isEditor, isSite } from '../../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../../common/actions/page-opened';
import { FEED_PAGE } from '../../../common/services/detect-route';
import { generateFeedSEOTags } from '../../../common/services/generate-seo-tags/generate-feed-seo-tags';
import { translate } from '../../../common/services/controller-translate';
import { setFeedSEOTags } from '../../actions/set-feed-seo-tags';
import { getFeedSEOTags } from '../../selectors/feed-seo-tags-selectors';
import { waitForAction } from '../../../common/store/wait-for-action';
import { SET_APP_SETTINGS } from '../../../common/store/app-settings/app-settings-actions';
import { getLastFeaturedPost } from '../../selectors/last-featured-post-selectors';
import { SET_BASIC_PARAMS } from '../../../common/store/basic-params/basic-params-actions';

export const createFeedPageRouter = (store, wixCodeApi) => async (
  { params },
  redirect,
  { preFetch, preFetchResult },
) => {
  const page = parseInt(get(params, 'page', '1'), 10);
  const shouldFetchBanner = () =>
    getAppSettingsValue({ state: store.getState(), key: IS_BANNER_ENABLED_PATH }) &&
    !getLastFeaturedPost(store.getState());
  const getPageSize = () => getPageSizeFromSettings(store.getState(), { section: SECTION_HOMEPAGE });

  const actions = preFetchResult || [
    store.dispatch(
      waitForAction(
        [SET_APP_SETTINGS, SET_BASIC_PARAMS],
        () =>
          fetchFeedPosts({
            page,
            pageSize: getPageSize(),
            excludeContent: isExcludePostContentSupported(store.getState(), SECTION_HOMEPAGE),
            section: SECTION_HOMEPAGE,
            preFetch,
          }),
        true,
      ),
    ),
    store.dispatch(
      waitForAction([SET_APP_SETTINGS], () => (shouldFetchBanner() ? fetchLastFeaturedPost(preFetch) : () => {}), true),
    ),
  ];

  if (preFetch) {
    return actions;
  }

  if (preFetchResult) {
    const [feedPosts, featuredPost] = await preFetchResult;
    await Promise.all([
      store.dispatch(completeFetchFeedPosts({ page, pageSize: getPageSize() }, feedPosts)),
      shouldFetchBanner() ? store.dispatch(completeFetchLastFeaturedPost(featuredPost)) : Promise.resolve(),
    ]);
  } else {
    await Promise.all(actions);
  }

  const showPagination = getShowPagination(store.getState(), SECTION_HOMEPAGE);
  const isValidPage = getIsValidPage(store.getState(), SECTION_HOMEPAGE);
  if (showPagination && !isValidPage) {
    redirect(ROUTE_404);
  }

  const state = store.getState();

  !isSSR(state) &&
    store.dispatch(pageOpened({ page: FEED_PAGE, isMobile: getIsMobile(state), isEditor: isEditor(state) }));

  if (isSite(state)) {
    !getFeedSEOTags(state) &&
      store.dispatch(
        setFeedSEOTags({
          title: wixCodeApi.seo.title,
          links: wixCodeApi.seo.links,
          metaTags: wixCodeApi.seo.metaTags,
        }),
      );

    const { title, links, metaTags } = generateFeedSEOTags({
      showPagination,
      state: store.getState(),
      page,
      t: translate,
    });

    wixCodeApi.seo.setLinks(links);
    wixCodeApi.seo.setMetaTags(metaTags);
    wixCodeApi.seo.setTitle(title);
  }
};
