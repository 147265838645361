import { setIsLoading } from '../../../common/store/is-loading/is-loading-actions';
import { search, clearSearchResults } from '../../../common/controller/lazy-actions';
import { MIN_QUERY_LENGTH } from '../../../search/actions/search-types';
import { decodeQuery } from '../../../search/services/query-encoding';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { pageOpened } from '../../../common/actions/page-opened';
import { SEARCH_RESULTS } from '../../../common/services/detect-route';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';

export const createSearchPageRouter = (store, wixCodeApi) => ({ params: { query } }) => {
  setMetaTagRobotsNoIndex(wixCodeApi);
  store.dispatch(clearSearchResults());
  if (query && query.length >= MIN_QUERY_LENGTH) {
    store.dispatch(setIsLoading('search', '', true));
    return !isSSR(store.getState()) && store.dispatch(search({ query: decodeQuery(query) }));
  }
  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: SEARCH_RESULTS }));
};
