import { getWixDataCategoryId, getWixDataTagId, POST_LIST_SETTINGS_PARAMS } from '@wix/communities-blog-client-common';
import { getPostListWidgetPageSize } from '../selectors/post-list-widget-page-size';
import { getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { handleCategoriesResponse } from '../../common/store/categories/fetch-categories';
import { handleExperimentsResponse } from '../../common/store/experiments/experiments-actions';
import { handleTranslationsResponse } from '../../common/store/translations/translations-actions';
import { handlePostListPostsResponse } from './fetch-post-list-posts';
import { getQueryLocale } from '../../common/selectors/locale-selectors';

export const fetchInitialData = () => async (dispatch, getState, { aggregatorRequest }) => {
  const state = getState();
  const pageSize = getPostListWidgetPageSize(state);
  const featuredOnly = getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath });
  const widgetCategoryId = getWixDataCategoryId(state);
  const widgetTagId = getWixDataTagId(state);
  const language = getQueryLocale(getState());

  const { experiments, translations, categories, posts } = await aggregatorRequest(
    `/aggregator/post-list-widget?${[
      pageSize && `pageSize=${pageSize}`,
      featuredOnly && `featuredOnly=${featuredOnly}`,
      widgetCategoryId && `widgetCategoryId=${widgetCategoryId}`,
      widgetTagId && `widgetTagId=${widgetTagId}`,
      language && `language=${language}`,
    ]
      .filter(Boolean)
      .join('&')}`,
  );

  await Promise.all([
    dispatch(handleExperimentsResponse(experiments)),
    dispatch(handleTranslationsResponse(translations)),
    dispatch(handleCategoriesResponse(categories)),
  ]);
  await dispatch(handlePostListPostsResponse(posts));
};
