import { createAction } from '@wix/communities-blog-client-common';

export const FETCH_PUBLICATIONS_COUNT_REQUEST = 'publicationsCount/FETCH_REQUEST';
export const FETCH_PUBLICATIONS_COUNT_SUCCESS = 'publicationsCount/FETCH_SUCCESS';
export const FETCH_PUBLICATIONS_COUNT_FAILURE = 'publicationsCount/FETCH_FAILURE';

export const fetchPublicationsCountRequest = createAction(FETCH_PUBLICATIONS_COUNT_REQUEST);
export const fetchPublicationsCountSuccess = createAction(FETCH_PUBLICATIONS_COUNT_SUCCESS);
export const fetchPublicationsCountFailure = createAction(FETCH_PUBLICATIONS_COUNT_FAILURE);

export const fetchPublicationsCount = () => {
  return (dispatch, _, { request }) => {
    dispatch(fetchPublicationsCountRequest());

    const promise = request(`/v2/stats/publications/total`);
    return promise.then(
      (publicationsCount) => dispatch(fetchPublicationsCountSuccess(publicationsCount || {})),
      () => dispatch(fetchPublicationsCountFailure()),
    );
  };
};
