import { isSSR } from '@wix/communities-blog-client-common';
import { fetchHashtagPosts } from '../../../common/actions/fetch-hashtag-posts';
import { HASHTAG_PAGE } from '../../../common/services/detect-route';
import { pageOpened } from '../../../common/actions/page-opened';
import { setMetaTagRobotsNoIndex } from '../../../common/services/set-metatag-robots-noindex';
import { ROUTE_404 } from '../../constants/routes';

export const createHashtagPageRouter = (store, wixCodeApi) => async ({ params }, redirect) => {
  setMetaTagRobotsNoIndex(wixCodeApi);
  !isSSR(store.getState()) && store.dispatch(pageOpened({ page: HASHTAG_PAGE }));
  const { hashtag, pageIndex } = params;

  if (hashtag) {
    try {
      const posts = await store.dispatch(fetchHashtagPosts(hashtag, pageIndex));
      if (!posts.length) {
        return redirect(ROUTE_404);
      }
    } catch (error) {
      if (error.status === 404) {
        redirect(ROUTE_404);
      }
    }
  } else {
    redirect(ROUTE_404);
  }
};
