import { createRequestWithBaseUrl, appendOriginInBackend, getInstance } from '../../controller/helpers';
import { parseInstance } from '../instance-values/parse-instance';
import { EXPERIMENTS_SCOPE } from './experiments-types';

export default function fetchExperimentsRequest({ wixCodeApi, baseUrls: { apiExperimentsBaseUrlClient }, bundleName }) {
  const { metaSiteId } = parseInstance(getInstance(wixCodeApi)());
  const request = createRequestWithBaseUrl({ wixCodeApi, bundleName });
  return request(appendOriginInBackend({ wixCodeApi, baseUrl: apiExperimentsBaseUrlClient }))(
    `/v1/laboratory/platform/conductAllInScope?scope=${EXPERIMENTS_SCOPE}&requestContext.overrideCriteria.entityId=${metaSiteId}`,
  );
}
